import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Thank you for using CDG ENGIE. All of our accounts are Postpaid by
          card, therefore we cannot process this cancellation. Be assured that
          your card will only be billed on the first business day of every month
          based on your charging activity from the previous month.
          <br /> <br />
          For additional support, please email us at <br />
          <a href="mailto:someone@example.com">support@cdgengie.com</a>
        </p>
      </header>
    </div>
  );
}

export default App;
